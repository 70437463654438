<app-page-container id="activity-container">
    <app-panel-layout>
        <app-panel-column left>
            <app-my-groups></app-my-groups>
            <app-member-access-requested></app-member-access-requested>
        </app-panel-column>
        <app-panel-column middle>
            <app-recent-activities></app-recent-activities>
            <app-more-like-this-latest-completion></app-more-like-this-latest-completion>
            <app-activity-feed></app-activity-feed>
        </app-panel-column>
        <app-panel-column right>
            <app-my-purchased-content></app-my-purchased-content>
            <app-my-events></app-my-events>
        </app-panel-column>
    </app-panel-layout>
</app-page-container>
