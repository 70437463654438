import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { constants } from '@pepconnect/constants';
import { ObjectCardSearchParameters } from '@pepconnect/dtos/search-request.dto';
import { ObjectCard } from '@pepconnect/models/object-card/object-card.model';
import { UserContentService } from '@pepconnect/services/user-content.service';
import { BaseV2SmartComponent } from '@pepconnect/shared/base/base-smart-component-v2';
import { selectAuthState } from '@pepconnect/state/auth/auth.selector';
import { AuthState } from '@pepconnect/state/auth/auth.state';
import { isPopulated } from '@pepconnect/utils/generic-utils';
import { filter, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-recent-activities',
  templateUrl: './recent-activities.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecentActivitiesComponent extends BaseV2SmartComponent implements OnInit {
  authState$ = this.store.select(selectAuthState);
  recentItems: ObjectCard[];
  hasRecentItems: boolean = false;
  loading: boolean = false;

  private readonly cd = inject(ChangeDetectorRef);
  private readonly userContentService = inject(UserContentService);

  ngOnInit(): void {
    this.authState$.pipe(filter(authState => this.initializationGuard(authState)), take(1))
      .subscribe(state => {
        this.fetchRecentItems(state);
      });
  }

  fetchRecentItems(state: AuthState) {
    this.loading = true;
    const requestParams: ObjectCardSearchParameters = {
      term: constants.SEARCH_DEFAULT_BLANK_SEARCH,
      size: constants.SEARCH_DEFAULT_CAROUSEL_SEARCH_LIMIT,
      skip: 0,
      locale: state.user.locale.locale,
      group: state.user.group?.groupID?.toString() ?? constants.USER_DEFAULT_GROUP_ID.toString(),
      filters: [],
      countryCode: state.user.country?.code,
      objects: [],
      timezoneId: state.user.timezone?.id
    }
    this.userContentService.fetchRecentItems(requestParams)
      .pipe(take(1))
      .subscribe(items => {
        this.loading = false;
        this.recentItems = items;
        this.hasRecentItems = isPopulated(items);
        this.cd.markForCheck();
      });
  }
}
