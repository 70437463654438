import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MarcomModule } from '@pepconnect-marcom/marcom.module';
import { ActivityRoutingModule } from '@pepconnect/features/activity/activity-routing.module';
import { ActivityComponent } from '@pepconnect/features/activity/activity.component';
import { FeedModule } from '@pepconnect/shared/feed/feed.module';
import { SharedModule } from '@pepconnect/shared/module';
import { EventsModule } from '../events/events.module';
import { RecentActivitiesComponent } from './recent-activities/recent-activities.component';
import { MyGroupsComponent } from './my-groups/my-groups.component';
import { TeamsSharedModule } from '../teams/teams-shared/teams-shared.module';
import { MyPurchasedContentComponent } from './my-purchased-content/my-purchased-content.component';
import { MemberAccessRequestedComponent } from './member-access-requested/member-access-requested.component';
import { MoreLikeThisComponent } from '@pepconnect/features/search/more-like-this/more-like-this.component';
import { MoreLikeThisLatestCompletionComponent } from './more-like-this-latest-completion/more-like-this-latest-completion.component';

@NgModule({
  declarations: [
    ActivityComponent,
    RecentActivitiesComponent,
    MyGroupsComponent,
    MyPurchasedContentComponent,
    MemberAccessRequestedComponent,
    MoreLikeThisComponent,
    MoreLikeThisLatestCompletionComponent
  ],
  imports: [
    CommonModule,
    ActivityRoutingModule,
    SharedModule,
    MarcomModule,
    FeedModule,
    EventsModule,
    TeamsSharedModule,
    TranslateModule.forChild({
      extend: true
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ActivityModule { }
