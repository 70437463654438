@if (!loading && showRecommendations) {
<div class="container home-carousel" data-ste-component="recommendation-carousel">
    <h5 class="font-family-headline" [innerHtml]="recommendationHeader"></h5>
    <uimc-card-slider custom-width="narrow">
        @for (item of moreLikeThisItems; track item.objectID)
        {
        <app-object-card-small class="override-marcom" [isCarousel]="true" [item]="item" [showCompletion]="false"></app-object-card-small>
        }
    </uimc-card-slider>
</div>
}
@else if (loading) {
<app-spinner></app-spinner>
}
