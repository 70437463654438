import { Component, inject } from '@angular/core';
import { UserObjectLaunch } from '@pepconnect/models/content/user-object-launch.model';
import { UserContentService } from '@pepconnect/services/user-content.service';
import { BaseV2SmartComponent } from '@pepconnect/shared/base/base-smart-component-v2';
import { isPopulated } from '@pepconnect/utils/generic-utils';
import { take } from 'rxjs';

@Component({
  selector: 'app-more-like-this-latest-completion',
  templateUrl: './more-like-this-latest-completion.component.html',
})
export class MoreLikeThisLatestCompletionComponent extends BaseV2SmartComponent {
  loading: boolean;
  showRecommendations: boolean;
  latestCompletion: UserObjectLaunch;
  userCompletions: string[];
  private readonly userContentService = inject(UserContentService);

  ngOnInit() {
    this.loading = true;
    this.checkFeatureFlag('pep-activity-recommendations')
      .subscribe(flag => {
        if (flag) {
          // only call API if flag is enabled
          this.initializeUserCompletions();
        } else {
          this.loading = false;
          this.showRecommendations = false;
        }
      });
  }

  initializeUserCompletions() {
    this.userContentService.fetchUserCompletions().pipe(take(1)).subscribe(completions => {
      if (isPopulated(completions)) {
        this.showRecommendations = true;
        this.latestCompletion = completions[0]; // api sends them back sorted, no need to sort here
        this.userCompletions = completions.map(x => x.id);
      } else {
        this.showRecommendations = false;
      }
      this.loading = false;
    });
  }
}

