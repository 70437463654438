<app-collection *ngIf="isEnabled && purchasedObjects?.length > 0">
    <app-collection-item>
        <app-collection-item-header>
            {{ 'TITLE_MYPURCHASEDCONTENT' | translate}}
        </app-collection-item-header>
    </app-collection-item>
    <app-collection-item [routerLink]="['/',routeLocale,'store','education-offerings-for-you']">
        <div class="display-flex flex-md-row flex-column align-items-center">
            <span class="icon-search"></span>
            <div class="display-flex flex-column align-items-left">
                <span>{{ 'LINK_BROWSEMOREPREMIUMCONTENT' | translate}}</span>
            </div>
        </div>
    </app-collection-item>
    <ng-container *ngFor="let o of purchasedObjects | slice:0:visibleRecords;">
        <app-collection-item class="clickable-collection-item" (click)="navigateToObjectPage(o?.linkUrl)">
            <uimc-label>{{ o.type?.name | translate }}</uimc-label>
            <h5 class="margin-zero font-size-xsmall" [innerHTML]="o.title"></h5>
            <p class="margin-zero">
                <em class="font-size-xsmall">{{ 'ADMIN_USERS_CERTIFICATES_PURCHASED' | translate}} {{ o.formattedPurchaseDate }}</em>
            </p>
            <p class="margin-zero">
                <em class="font-size-xsmall" *ngIf="o.expires">{{ 'TEXT_EXPIRESON' | translate}} {{ o.formattedExpirationDate }}</em>
            </p>
        </app-collection-item>
    </ng-container>
    <app-collection-item (click)="toggleViewAll()" *ngIf="showViewAll" class="clickable-collection-item">
        <span class="icon-arrow-right"></span>
        <span>{{ purchasedObjects?.length > visibleRecords ? ('LINK_VIEWALL' | translate) : ('LINK_VIEWLESS' | translate )}}</span>
    </app-collection-item>
    
</app-collection>
