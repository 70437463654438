import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { constants } from '@pepconnect/constants';
import { PageRequirement } from '@pepconnect/enums/page-requirement.enum';
import { ActivityComponent } from '@pepconnect/features/activity/activity.component';
import { withCommonRouteData } from '@pepconnect/functions/route-data';

const routes: Routes = [{ path: '', component: ActivityComponent, data: { ...withCommonRouteData(PageRequirement.ShouldBeLoggedIn, [constants.SITE_TITLE, 'TITLE_ACTIVITYFEED'])} }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ActivityRoutingModule { }
