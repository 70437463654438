import { Component, Input, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { constants } from '@pepconnect/constants';
import { ObjectCardSearchParameters } from '@pepconnect/dtos/search-request.dto';
import { ObjectCard } from '@pepconnect/models/object-card/object-card.model';
import { SearchService } from '@pepconnect/services/search.service';
import { BaseV2SmartComponent } from '@pepconnect/shared/base/base-smart-component-v2';
import { isPopulated } from '@pepconnect/utils/generic-utils';
import searchHelpers from '@pepconnect/utils/search-helpers';
import { filter, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-more-like-this',
  templateUrl: './more-like-this.component.html'
})
export class MoreLikeThisComponent extends BaseV2SmartComponent implements OnInit {
  @Input() objectId: string;
  @Input() title: string;
  @Input() userCompletions: string[];

  moreLikeThisItems: ObjectCard[];
  loading: boolean;
  showRecommendations: boolean = false;
  recommendationHeader: boolean = false;

  private readonly searchService = inject(SearchService);
  private readonly translateService = inject(TranslateService);

  ngOnInit(): void {
    this.loading = true;
    this.authState$.pipe(filter(authState => this.initializationGuard(authState)), take(1))
      .subscribe(state => {
        const requestParams: ObjectCardSearchParameters = {
          term: constants.SEARCH_DEFAULT_BLANK_SEARCH,
          size: constants.SEARCH_DEFAULT_CAROUSEL_SEARCH_LIMIT,
          skip: 0,
          locale: state.user.locale.locale,
          group: state.user.group?.groupID?.toString() ?? constants.USER_DEFAULT_GROUP_ID.toString(),
          filters: [],
          countryCode: state.user.country?.code,
          objects: this.userCompletions,
          timezoneId: state.user.timezone?.id,
          objectId: this.objectId
        }
        this.searchService.fetchMoreLikeThis(requestParams)
          .pipe(take(1))
          .subscribe(response => {
            this.moreLikeThisItems = searchHelpers.mapResults(response);
            this.showRecommendations = isPopulated(this.moreLikeThisItems);
            this.recommendationHeader = this.translateService.instant('LABEL_MORE_LIKE_THIS_HEADER').replace('@@NAME@@', this.title);
            this.loading = false;
          });
    });
  }

}

