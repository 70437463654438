import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { constants } from '@pepconnect/constants/constants';
import { UserPurchase } from '@pepconnect/models/user/user-purchase.model';
import { UserPurchasedService } from '@pepconnect/services/user-purchased.service';
import { BaseSmartComponent } from '@pepconnect/shared/base/base-smart-component/base-smart.component';
import { AppState } from '@pepconnect/state';
import { selectAuthState } from '@pepconnect/state/auth/auth.selector';
import { combineLatest, takeUntil } from 'rxjs';


@Component({
  selector: 'app-my-purchased-content',
  templateUrl: './my-purchased-content.component.html',
  encapsulation: ViewEncapsulation.Emulated
})
export class MyPurchasedContentComponent extends BaseSmartComponent implements OnInit {

  purchasedObjects: UserPurchase[];
  routeLocale: string;
  isEnabled: boolean;
  showViewAll: boolean;
  $authState = this.store.select(selectAuthState);
  $purchasedObjects = this.userPurchasedService.fetchPurchasedObjects();
  visibleRecords: number = constants.DEFAULT_MY_PURCHASED_OBJECTS_SIZE;

  constructor(
    private userPurchasedService: UserPurchasedService, protected store: Store<AppState>, private router: Router) {
    super(store);
  }

  ngOnInit(): void {
    combineLatest([this.$authState, this.$purchasedObjects]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(([authState, data]) => {
      this.routeLocale = authState.user.locale.locale;
      this.isEnabled = authState.user.flags && authState.user.flags[constants.FLAG_INDIVIDUAL_PURCHASE];
      this.purchasedObjects = data;
      this.showViewAll = this.purchasedObjects?.length > constants.DEFAULT_MY_PURCHASED_OBJECTS_SIZE;
    });
  }

  toggleViewAll(): void {
    if (this.visibleRecords < this.purchasedObjects.length) {
      this.visibleRecords = this.purchasedObjects.length;
    } else {
      this.visibleRecords = constants.DEFAULT_MY_PURCHASED_OBJECTS_SIZE;
    }
  }

  navigateToObjectPage(link: string) {
    this.router.navigateByUrl(link);
  }
}

