import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseSmartComponent } from '@pepconnect/shared/base/base-smart-component/base-smart.component';
import { AppState } from '@pepconnect/state/index';
import { TeamsService } from '@pepconnect/features/teams/services/teams.service';
import { Team, Teams } from '@pepconnect/features/teams/models/team.model';
import { Subject, take, takeUntil } from 'rxjs';
import { isPopulated } from '@pepconnect/utils/generic-utils';
import { User } from '@pepconnect/models/user/user.model';

@Component({
  selector: 'app-member-access-requested',
  templateUrl: './member-access-requested.component.html'
})
export class MemberAccessRequestedComponent extends BaseSmartComponent implements OnInit {
  loading: boolean = true;
  requests: Teams;
  hasRequests: boolean;
  user: User;
  $refresh = new Subject<string>();

  constructor(protected store: Store<AppState>, private teamsService: TeamsService) {
    super(store);
  }

  ngOnInit(): void {
    this.$user.pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => {
      this.user = user;
    });
    this.$authState.pipe(take(1)).subscribe({
      next: (authState) => {
        this.authState = authState;
      }
    });
    
    this.teamsService.fetchTeamRequests().pipe(take(1)).subscribe(teamRequests => {
      this.requests = teamRequests;
      this.hasRequests = isPopulated(this.requests);
      this.loading = false;
    })
  }

  removeRequest(team: Team): void {
    this.requests = this.requests.filter(t => t.teamID !== team.teamID);
    this.hasRequests = isPopulated(this.requests);
    this.$refresh.next(team.teamID);
  }

  trackRequest(index: number, team: Team): string {
    return team.teamID;
  }
}
