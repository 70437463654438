import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { constants } from '@pepconnect/constants';
import { TeamMember, TeamMemberResults } from '@pepconnect/features/teams/models/team-member.model';
import { TeamsService } from '@pepconnect/features/teams/services/teams.service';
import { User } from '@pepconnect/models/user/user.model';
import { BaseSmartComponent } from '@pepconnect/shared/base/base-smart-component/base-smart.component';
import { selectUserLocale } from '@pepconnect/state/auth/auth.selector';
import { AppState } from '@pepconnect/state/index';
import { take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-my-groups',
  templateUrl: './my-groups.component.html',
  styleUrls: ['./my-groups.component.scss']
})
export class MyGroupsComponent extends BaseSmartComponent implements OnInit {
  loading: boolean = true;
  teamMembers: TeamMember[];
  totalCount: number;
  user: User;
  locale: string;
  $locale = this.store.select((selectUserLocale));
  
  constructor(
    protected store: Store<AppState>,
    private teamsService: TeamsService,
    private router: Router) {
    super(store);
  }

  ngOnInit(): void {
    this.$authState.pipe(take(1)).subscribe({
      next: (authState) => {
        this.authState = authState;
      }
    });

    this.setLocale();
    this.getTeamMembers();
    this.$user.pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => {
      this.user = user;
    });
  }

  getTeamMembers() {
    this.teamsService.fetchTeamsByPageWithSearch(constants.DEFAULT_GROUPS_LIST_SIZE, 1, null).pipe(takeUntil(this.ngUnsubscribe)).subscribe((result: TeamMemberResults) => {
      this.teamMembers = result?.teamMembers ?? [];
      this.teamMembers.sort((a, b) => { return this.compareRequestThenInviteThenName(a, b) });
      this.totalCount = result?.totalCount ?? 0;
      this.loading = false;
    });
  }

  compareRequestThenInviteThenName(a: TeamMember, b: TeamMember): number {
    // first sort by pending requests
    if (a.requestPending !== b.requestPending) {
      return a.isPending ? -1 : 1;
    }
    else {
      // then anything else pending (invites)
      if (a.isPending !== b.isPending) {
        return a.isPending ? -1 : 1;
      }
      else { // finally team name
        return a.team?.name.localeCompare(b.team?.name);
      }
    }
  }

  setLocale(): void {
    this.$locale.pipe(takeUntil(this.ngUnsubscribe)).subscribe(l => {
      this.locale = l.locale;
    })
  }

  goToGroups(): void {
    this.router.navigate([this.locale, 'groups']);
  }

  createGroup(): void {
    this.router.navigate([this.locale, 'groups', 'new', 'details']);
  }

  refreshList() {
    this.getTeamMembers();
  }

}
