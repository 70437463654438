<app-collection *ngIf="!loading && hasRequests && !authState?.isInAdminGroup">
    <app-collection-item>
        <app-collection-item-header class="display-block margin-bottom-half">{{'MEMBER_STATUS_REQUESTED' | translate}}</app-collection-item-header>
    </app-collection-item>
    <app-collection-item>
        <ng-container *ngFor="let requestTeam of requests; trackBy: trackRequest">
            <app-member-request [requestTeam]="requestTeam" (removeRequest)="removeRequest(requestTeam)"></app-member-request>
        </ng-container>
    </app-collection-item>
</app-collection>
