<app-collection>
    <app-collection-item>
        <app-collection-item-header>
            {{'TITLE_MYGROUPS' | translate}}
        </app-collection-item-header>
    </app-collection-item>
    <app-collection-item *ngIf="!loading && authState?.isInAdminGroup">
        <p>{{'GROUP_MESSAGE_NOADMIN' | translate}}</p>
    </app-collection-item>
    <div *ngIf="!loading && authState && !authState?.isInAdminGroup">
        <ng-container *ngFor="let member of teamMembers">
            <app-collection-item [highlight]="member.teamMemberStatus === 'MEMBER_STATUS_INVITED'">
                <app-member-join-invite [member]="member" [isActivityPage]="true" (accepted)="refreshList()"
                    (denied)="refreshList()"></app-member-join-invite>
            </app-collection-item>
        </ng-container>
        <app-collection-item class="clickable" *ngIf="teamMembers.length < totalCount" (click)="goToGroups()">
            <i class="icon-arrow-right"></i>
            <span>
                {{ 'LINK_VIEWALL' | translate}}
            </span>
        </app-collection-item>
    </div>
    <app-collection-item *ngIf="authState && !authState?.isInAdminGroup" (click)="createGroup()" class="clickable">
        <i class="icon-plus"></i>
        <span>
            {{ 'LINK_CREATEGROUP' | translate}}
        </span>
    </app-collection-item>
</app-collection>
