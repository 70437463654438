@if(hasRecentItems && !loading) {
    <div class="container home-carousel" data-ste-component="recommendation-carousel">
        <h5 class="font-family-headline">{{ 'LABEL_CAROUSEL_CONTINUE' | translate }}</h5>
        <uimc-card-slider custom-width="narrow">
            @for (item of recentItems; track item.objectID)
            {
                <app-object-card-small class="override-marcom" [isCarousel]="true" [item]="item" [showCompletion]="true"></app-object-card-small>
            }
        </uimc-card-slider>
    </div>
}
@else if (loading) {
    <app-spinner [centered]="true"></app-spinner>
}

